import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'
import tmIcon from '../images/tobias-meixner-com-logo-small.png'
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {        
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}          
        >
          <html lang="en" />
          <meta http-equiv="content-type" content="text/html;charset=utf-8" />                    
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="robots" content="index,follow" />
          <meta name="author" content="Tobias Meixner" />
          <link rel="author" href="https://plus.google.com/+TobiasMeixner1" />
          <link href="https://plus.google.com/+tobiasmeixnercom" rel="publisher" />          
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.tobias-meixner.com/" />
          <meta property="og:image" content="https://www.tobias-meixner.com/tobias-meixner-com-logo.png" />
          <meta property="og:site_name" content="Tobias Meixner" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="MeixnerTobias" />          
          <meta name="twitter:creator" content="MeixnerTobias" />
          <meta name="twitter:image:src" content="https://api.twitter.com/1/users/profile_image?screen_name=MeixnerTobias&amp;size=bigger" />
          <meta name="twitter:domain" content="https://www.tobias-meixner.com/" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          {/* <link rel="meta" type="application/rdf+xml" href="https://www.tobias-meixner.com/tobias-meixner-com.rdf" /> */}
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          className="contentWrapper"          
        >
          {children}
        </div>
        <footer>
          <span>Tobias</span>
          <img src={tmIcon} alt="Tobias Icon" />
          <span>Meixner</span>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
