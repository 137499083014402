import React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import { fluidImage } from '../fragments'
import Img from 'gatsby-image'
import Tobias from '../images/tobias-meixner-head.jpg'
const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        Tobias: file(relativePath: { eq: "tobias-meixner-head.jpg" }) {
          ...fluidImage
        }
      }
    `}
    render={(data) => (
      <header>
        <div className="headerContainer">
          <span style={{ margin: 0 }}>
            <Link activeClassName="active" to="/" style={{}}>
              <img
                // fluid={data.Tobias.childImageSharp.fluid}
                src={Tobias}
                style={{
                  zIndex: 100,
                  objectFit: 'cover',
                  objectPosition: 'top',
                  width: '50px',
                  height: '50px',
                  margin: '0 auto 0 auto',
                  display: 'block',
                  position: 'relative',
                  left: 0,
                  right: 0,
                  borderRadius: '100%',
                }}
                alt="Tobias Meixner"
              />
              {siteTitle}
            </Link>
          </span>
        </div>
        <nav>
          <ul>
            <li>
              <Link activeClassName="active" to="/about">
                About
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/experience">
                Experience
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/projects">
                Projects
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/angel-investor">
                Angel
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/fractional-cto">
                Fractional CTO
              </Link>
            </li>
            <li>
              {/* <Link
          activeClassName="active"
          to="/blog"
        >
        Blog
      </Link> */}
            </li>
            <li>
              <Link activeClassName="active" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    )}
  />
)

export default Header
